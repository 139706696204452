<template>
	<div id="container-instant" class="col-10 col-md-8 col-lg-6 col-xl-4 d-flex justify-content-center py-4 p-md-4 p-lg-5"
		:class="{
			'h-700': height === 700,
			'h-800': height === 800
		}"
	>
		<div class="col-12 d-flex flex-column align-items-center">
			<div class="d-flex justify-content-center align-items-center logo-container">
				<img class="logo" :src="logo_url" alt="logo-instant">
			</div>
			<div class="col d-flex flex-column align-items-center mt-5 py-3 py-lg-0 entries">
				<h1 v-if="sub_type !== 'recovery'"
					class="title"
					:class="{ 
							'mt-4': sub_type === 'login',
							'mt-3': sub_type === 'register'
						}"
				>
					BEM-VINDO!
				</h1>
				<div v-if="sub_type == 'login'" class="col mt-5 login d-flex flex-column justify-content-end">
					<div class="form-group">
						<label for="login">CPF ou E-mail</label>
						<input 
							id="login"
							type="text"
							class="form-control"
							:class="{ 'is-invalid': $v.login.username.$error }"
							v-model="$v.login.username.$model"
							@keyup.13="LoginContent"
							autocomplete="username"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="password">Senha</label>
						<input 
							id="password"
							type="password"
							class="form-control"
							:class="{ 'is-invalid': $v.login.password.$error }"
							v-model="$v.login.password.$model"
							@keyup.13="LoginContent"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<span>
						<a class="link" @click="sub_type = 'recovery'">Esqueceu a senha</a>
					</span>
					<button 
						class="btn btn-primary mt-4 py-2"
						@click="LoginContent()"
					>
						<span class="text-uppercase">Login</span>
					</button>
					<div clas="col-12">
						<p class="text-center m-0 mt-2">
							ou
						</p>
						<p class="text-center m-0">
							<router-link :to="{ name: 'Register' }">Cadastre-se agora!</router-link>
						</p>
					</div>
				</div>
				<div v-else-if="sub_type == 'register'" class="col mt-4 pt-2 registry">
					<div class="form-group">
						<label for="name">Nome Completo</label>
						<input 
							id="name"
							type="text"
							class="form-control"
							:class="{ 'is-invalid': $v.register.name.$error }"
							v-model="$v.register.name.$model"
							@keyup.enter="Register()"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<div class="form-group">
						<label for="email">E-mail</label>
						<input 
							id="email"
							type="email"
							class="form-control"
							:class="{ 'is-invalid': $v.register.email.$error }"
							v-model="$v.register.email.$model"
							@keyup.enter="Register()"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<button 
						class="col-12 btn btn-primary py-2 mt-4 mb-3"
						@click="Register()"
					>
						<span class="text-uppercase">Cadastrar</span>
					</button>
					<!-- <div class="col-12 px-0">
						<p class="text-center">
							Ao continuar, você concorda com o nosso <router-link :to="{ name: 'ServiceTermsIndex'}">termo de serviço</router-link> e <router-link :to="{ name: 'PrivacyPolicyIndex'}">política de privacidade</router-link>.
						</p>
					</div> -->
					<div clas="col-12">
						<p class="text-center m-0 mt-3">
							Já está cadastrado? <router-link :to="{ name: 'Login' }">Entrar</router-link>
						</p>
					</div>
				</div>
				<div v-else-if="sub_type == 'recovery'" class="col mt-3 pt-2 recovery">
					<p class="text-center my-1">
						Informe seu e-mail de acesso para iniciar o processo de recuperação da senha, você receberá uma mensagem com os proximos passos.
					</p>
					<div class="form-group mt-5">
						<label for="email">E-mail</label>
						<input 
							id="email"
							type="email"
							class="form-control"
							:class="{ 'is-invalid': $v.recovery.email.$error }"
							v-model="$v.recovery.email.$model"
							@keyup.13="RecoveryPasswd"
						>
						<div class="invalid-feedback">
							Obrigatório
						</div>
					</div>
					<button 
						class="col-12 btn btn-primary py-2"
						@click="RecoveryPasswd()"
					>
						<span class="text-uppercase">Enviar</span>
					</button>
					<div clas="col-12">
						<p class="text-center m-0 mt-3">
							<a class="link" @click="sub_type = 'login'">Voltar</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

const api_url 		= `${process.env.VUE_APP_API_URL}/api`

export default {
    components: {

    },
	props: {
		bridge_key:	{
			type:		String,
			default:	null
		},
		type:		{
			type:		String,
			default:	'login'
		},
		height:		{
			type:		Number,
			default:	700
		},
		plan_id:		{
			type:		[String, Number],
			default:	''
		},
	},
	data() {
		return {
			sub_type:		'login',
			login: 		{
				username:	'',
				password:	'',
			},
			register: 	{
				name: 		'',
				email:		''
			},
			recovery:	{
				email:		''
			},
			logo_url: 		require('../../../assets/img/logo_original.png'),
			management_url: process.env.VUE_APP_MANAGEMENT_URL
		}
	},
	validations: {
		login: 		{
			username: 	{
				required
			},
			password: 	{
				required
			}
		},
		register: 	{
			name: 		{
				required
			},
			email: 		{
				required
			}
		},
		recovery: 	{
			email:		{
				required
			}
		}
	},
	watch: {
		bridge_key: {
			handler(val) {
				if(val) {
					location.href 			= `${this.management_url}/auth/token/${val}`
				}
			},
			immediate: true
		},
		type: {
			handler(val) {
				this.sub_type = val
			},
			immediate: true
		}
	},
	methods: {
		...mapActions('auth', [
			'Login'
		]),
		LoginContent() {
			this.$v.login.$touch()

			if(!this.$v.login.$invalid) {
				Swal.showLoading()

				this.Login(this.login)
					.then(data => {
						if(data.has_temp_password) {
							this.$router.push({ name: 'RecoveryPasswordWithTokenIndex', params: { 
									token: data.token,
									bridge_key: data.bridge_key
								} 
							})

							return
						}

						location.href 			= `${this.management_url}/auth/token/${data.bridge_key}`
					}).catch((response) => {
						if(!response) {
							Swal.fire({
								title:	'Opss..',
								text: 	'Houve um erro ao concluir, contate o suporte.',
								icon: 	'error'
							})

							return
						} else {
							if(response.data.error === "invalid_credentials") {
								Swal.fire({
									title:	'Opss..',
									text: 	'Usuário e/ou senha inválida.',
									icon: 	'error'
								})
							}
						}
					})
			}
		},
		RecoveryPasswd(){
			return window.axios.post(`${api_url}/website/send-recovery-password-email`, {
				email: this.recovery.email
			}).then(({data}) => {
				if(!data.response) {
					throw new Error(data.message)
				}

				if(data.response) {
					Swal.fire({
						title:	'Email enviado',
						text: 	'Verifique sua caixa de email para continuar o processo.',
						icon: 	'success'
					})
				}
			})
		},
		Register() {
			this.$v.register.$touch()

			if(!this.$v.register.$invalid) {
				Swal.showLoading()

				return window.axios.post(`${api_url}/website/send-validation-email`, {
						...this.register,
						pre_plan: this.plan_id
					})
					.then(({data}) => {
						if(data.response) {
							if(data.resend) {
								Swal.fire({
									title:	'Email reenviado',
									text: 	'Verifique sua caixa de email para continuar o cadastro.',
									icon: 	'info'
								}).then((result) => {
									this.ClearRegister()
								})
							} else {
								Swal.fire({
									title:	'Email enviado',
									text: 	'Verifique sua caixa de email para continuar o cadastro.',
									icon: 	'info'
								}).then((result) => {
									this.ClearRegister()
								})
							}
						}

						if(data.exists) {
							Swal.fire({
								title:	'Email já cadastrado',
								text: 	'O email informado já está vinculado a uma conta.',
								icon: 	'warning'
							})
						}
					}).catch(({response}) => {
						if(response.data?.exists) {
							Swal.fire({
								title:	'Email já cadastrado',
								text: 	'O email informado já está vinculado a uma conta.',
								icon: 	'warning'
							})

							return
						}

						Swal.fire({
							title:	'Opss..',
							text: 	'Houve um erro ao concluir, contate o suporte.',
							icon: 	'error'
						})
					})
			}
		},
		ClearRegister() {
			this.register.name 		= ''
			this.register.email 	= ''

			this.$v.register.$reset()
		},
	}
}
</script>

<style lang="css" scoped>
	.recovery .btn-primary {
		margin-top: 5rem;
	}


</style>